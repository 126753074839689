import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Sidebar} from "primereact/sidebar";
import {TabView, TabPanel} from 'primereact/tabview';
import {Dialog} from "primereact/dialog";

import CompanyFormComponent from "../../components/company/CompanyFormComponent";
import CompanyListComponent from "../../components/company/CompanyListComponent";
import CosttypeFormComponent from "../../components/costtype/CosttypeFormComponent";
import CosttypeListComponent from "../../components/costtype/CosttypeListComponent";
import PaymentmethodFormComponent from "../../components/paymentmethod/PaymentmethodFormComponent";
import PaymentmethodListComponent from "../../components/paymentmethod/PaymentmethodListComponent";
import UnitFormComponent from "../../components/unit/UnitFormComponent";
import UnitListComponent from "../../components/unit/UnitListComponent";
import ReceiptgroupFormComponent from "../../components/receiptgroup/ReceiptgroupFormComponent";
import ReceiptgroupListComponent from "../../components/receiptgroup/ReceiptgroupListComponent";
import UserListComponent from "../../components/user/UserListComponent";
import UserFormComponent from "../../components/user/UserFormComponent";
import ProjectstatusFormComponent from "../../components/projectstatus/ProjectstatusFormComponent"
import ProjectstatusListComponent from "../../components/projectstatus/ProjectstatusListComponent";
import ProjecttypeFormComponent from "../../components/projecttype/ProjecttypeFormComponent";
import ProjecttypeListComponent from "../../components/projecttype/ProjecttypeListComponent";
import RolegroupListComponent from "../../components/rolegroup/RolegroupListComponent";
import RolegroupFormComponent from "../../components/rolegroup/RolegroupFormComponent";
import IdentifierListComponent from "../../components/identifier/IdentifierListComponent";
import IdentifierFormComponent from "../../components/identifier/IdentifierFormComponent";
import ManufacturerListComponent from "../../components/manufacturer/ManufacturerListComponent";
import WarehouseListComponent from "../../components/warehouse/WarehouseListComponent";
import ManufacturerFormComponent from "../../components/manufacturer/ManufacturerFormComponent";
import WarehouseFormComponent from "../../components/warehouse/WarehouseFormComponent";
import CalendartypeListComponent from "../../components/calendartype/CalendartypeListComponent";
import CalendartypeFormComponent from "../../components/calendartype/CalendartypeFormComponent";
import EmployeenationalityListComponent from "../../components/employeenationality/EmployeenationalityListComponent";
import EmployeenationalityFormComponent from "../../components/employeenationality/EmployeenationalityFormComponent";
import ActivitygroupListComponent from "../../components/activitygroup/ActivitygroupListComponent";
import ActivitygroupFormComponent from "../../components/activitygroup/ActivitygroupFormComponent";
import ActivitysubgroupFormComponent from "../../components/activitysubgroup/ActivitysubgroupFormComponent";
import DocumenttypeListComponent from "../../components/documenttype/DocumenttypeListComponent";
import DocumenttypeFormComponent from "../../components/documenttype/DocumenttypeFormComponent";
import DocumenttemplateListComponent from "../../components/documenttemplate/DocumenttemplateListComponent";
import DocumenttemplateFormComponent from "../../components/documenttemplate/DocumenttemplateFormComponent";
import EmploymentworkorderListComponent from "../../components/employmentworkorder/EmploymentworkorderListComponent";
import EmploymentworkorderFormComponent from "../../components/employmentworkorder/EmploymentworkorderFormComponent";
import UnasFormComponent from "../../components/unas/UnasFormComponent";
import UnasListComponent from "../../components/unas/UnasListComponent";
import BankaccountListComponent from "../../components/bankaccount/BankaccountListComponent";
import BankaccountFormComponent from "../../components/bankaccount/BankaccountFormComponent";
import TechnologyFormComponent from "../../components/technology/TechnologyFormComponent";
import TechnologyListComponent from "../../components/technology/TechnologyListComponent";
import PricelistitemFormComponent from "../../components/pricelistitem/PricelistitemFormComponent";
import PricelistitemListComponent from "../../components/pricelistitem/PricelistitemListComponent";
import EmploymenttypeListComponent from "../../components/employmenttype/EmploymenttypeListComponent";
import EmploymenttypeFormComponent from "../../components/employmenttype/EmploymenttypeFormComponent";
import ProjectitemstatusListComponent from "../../components/projectitemstatus/ProjectitemstatusListComponent";
import ProjectitemstatusFormComponent from "../../components/projectitemstatus/ProjectitemstatusFormComponent";
import MetricsListComponent from "../../components/metrics/MetricsListComponent";
import MetricsFormComponent from "../../components/metrics/MetricsFormComponent";
import WorkflowFormComponent from "../../components/workflow/WorkflowFormComponent";
import MachineListComponent from "../../components/machine/MachineListComponent";
import MachineFormComponent from "../../components/machine/MachineFormComponent";

export const SettingsDashboardPage = (props) => {

  const {t} = props;

  useEffect(() => {
    window.App.pageHeight()
    document.title = localStorage.getItem('module_settings') + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('settings')[0]) {
        document.getElementsByClassName('settings')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_settings') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_identifier_read') ? (
                  <TabPanel header="Azonosítók">
                    <div className={"p-px-2 p-pb-2"}>
                      <IdentifierListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_user_read') ? (
                  <TabPanel header="Felhasználók">
                    <div className={"p-px-2 p-pb-2"}>
                      <UserListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_company_read') ? (
                  <TabPanel header="Vállalkozások">
                    <div className={"p-px-2 p-pb-2"}>
                      <CompanyListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_bankaccount_read') ? (
                  <TabPanel header="Bankszámlák">
                    <div className={"p-px-2 p-pb-2"}>
                      <BankaccountListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_role_group_read') ? (
                  <TabPanel header="Szerepkörök">
                    <div className={"p-px-2 p-pb-2"}>
                      <RolegroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_cost_type_read') ? (
                  <TabPanel header="Költségnemek">
                    <div className={"p-px-2 p-pb-2"}>
                      <CosttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_payment_method_read') ? (
                  <TabPanel header="Fizetési módok">
                    <div className={"p-px-2 p-pb-2"}>
                      <PaymentmethodListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_unit_read') ? (
                  <TabPanel header="Mennyiségi egységek">
                    <div className={"p-px-2 p-pb-2"}>
                      <UnitListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_receipt_group_read') ? (
                  <TabPanel header="Bizonylat tömbök">
                    <div className={"p-px-2 p-pb-2"}>
                      <ReceiptgroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header={t('project') + " státuszok"}>
                    <div className={"p-px-2 p-pb-2"}>
                      <ProjectstatusListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header={t('project') + " típusok"}>
                    <div className={"p-px-2 p-pb-2"}>
                      <ProjecttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header={t('project') + " tétel státuszok"}>
                    <div className={"p-px-2 p-pb-2"}>
                      <ProjectitemstatusListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Metrikák">
                    <div className={"p-px-2 p-pb-2"}>
                      <MetricsListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_document_type_read') ? (
                  <TabPanel header="Dokumentum típusok">
                    <div className={"p-px-2 p-pb-2"}>
                      <DocumenttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_document_template_read') ? (
                  <TabPanel header="Dokumentum sablonok">
                    <div className={"p-px-2 p-pb-2"}>
                      <DocumenttemplateListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_machine_read') ? (
                  <TabPanel header="Gépek">
                    <div className={"p-px-2 p-pb-2"}>
                      <MachineListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_manufacturer_read') ? (
                  <TabPanel header="Gyártók">
                    <div className={"p-px-2 p-pb-2"}>
                      <ManufacturerListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_warehouse_read') ? (
                  <TabPanel header="Raktárak">
                    <div className={"p-px-2 p-pb-2"}>
                      <WarehouseListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_event_type_read') ? (
                  <TabPanel header="Esemény típusok">
                    <div className={"p-px-2 p-pb-2"}>
                      <CalendartypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_employee_nationality_read') ? (
                  <TabPanel header="Állampolgárságok">
                    <div className={"p-px-2 p-pb-2"}>
                      <EmployeenationalityListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_activity_group_read') ? (
                  <TabPanel header="Tevékenységek">
                    <div className={"p-px-2 p-pb-2"}>
                      <ActivitygroupListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_employment_work_order_read') ? (
                  <TabPanel header="Munkarendek">
                    <div className={"p-px-2 p-pb-2"}>
                      <EmploymentworkorderListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_employment_type_read') ? (
                  <TabPanel header="Foglalkoztatás formák">
                    <div className={"p-px-2 p-pb-2"}>
                      <EmploymenttypeListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_unas_read') ? (
                  <TabPanel header="UNAS">
                    <div className={"p-px-2 p-pb-2"}>
                      <UnasListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_technology_read') ? (
                  <TabPanel header="Technológiák">
                    <div className={"p-px-2 p-pb-2"}>
                      <TechnologyListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_pricelist_item_read') ? (
                  <TabPanel header="Árlisták">
                    <div className={"p-px-2 p-pb-2"}>
                      <PricelistitemListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
              </TabView>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarCompanyCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCompanyCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új vállalkozás</h2>
            <CompanyFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCompanyUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCompanyUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Vállalkozás módosítása</h2>
            <CompanyFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarBankaccountCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarBankaccountCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új bankszámla</h2>
            <BankaccountFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarBankaccountUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarBankaccountUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Bankszámla módosítása</h2>
            <BankaccountFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUserCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUserCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új felhasználó</h2>
            <UserFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUserUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarUserUpdate: false,
                       useEffect: true,
                     })
                   }}>
            <h2 className={"text-primary"}>Felhasználó módosítása</h2>
            <UserFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarRolegroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarRolegroupCreate: false,
                       useEffect: true,
                     })
                   }}>
            <h2 className={"text-primary"}>Új szerepkör</h2>
            <RolegroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarRolegroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarRolegroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Szerepkör módosítása</h2>
            <RolegroupFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCosttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCosttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új költségnem</h2>
            <CosttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarIdentifierCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarIdentifierCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új azonosító</h2>
            <IdentifierFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarIdentifierUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarIdentifierUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Azonosító módosítása</h2>
            <IdentifierFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCosttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCosttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Költségnem módosítása</h2>
            <CosttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPaymentmethodCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPaymentmethodCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új fizetési mód</h2>
            <PaymentmethodFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPaymentmethodUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPaymentmethodUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Fizetési mód módosítása</h2>
            <PaymentmethodFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnitCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnitCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új mennyiségi egység</h2>
            <UnitFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnitUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnitUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Mennyiséi egység módosítása</h2>
            <UnitFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptgroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarReceiptgroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új bizonylat tömb</h2>
            <ReceiptgroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptgroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarReceiptgroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Bizonylat tömb módosítása</h2>
            <ReceiptgroupFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectstatusCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectstatusCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új {t('project').toLowerCase()} státusz</h2>
            <ProjectstatusFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectstatusUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectstatusUpdate: false})
                   }}>
            <h2 className={"text-primary"}>{t('project')} státusz módosítása</h2>
            <ProjectstatusFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjecttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjecttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új {t('project').toLowerCase()} típus</h2>
            <ProjecttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjecttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjecttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>{t('project')} típus módosítása</h2>
            <ProjecttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectitemstatusCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectitemstatusCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új {t('project').toLowerCase()} tétel státusz</h2>
            <ProjectitemstatusFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectitemstatusUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectitemstatusUpdate: false})
                   }}>
            <h2 className={"text-primary"}>{t('project')} tétel státusz módosítása</h2>
            <ProjectitemstatusFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMetricsCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarMetricsCreate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Új metrika</h2>
            <MetricsFormComponent />
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMetricsUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarMetricsUpdate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Metrika módosítása</h2>
            <MetricsFormComponent />
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új dokumentum típus</h2>
            <DocumenttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Dokumentum típus módosítása</h2>
            <DocumenttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttemplateCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttemplateCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új dokumentum sablon</h2>
            <DocumenttemplateFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttemplateUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttemplateUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Dokumentum sablon módosítása</h2>
            <DocumenttemplateFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarManufacturerCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarManufacturerCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új gyártó</h2>
            <ManufacturerFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarManufacturerUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarManufacturerUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Gyártó módosítása</h2>
            <ManufacturerFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWarehouseCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarWarehouseCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új raktár</h2>
            <WarehouseFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWarehouseUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarWarehouseUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Raktár módosítása</h2>
            <WarehouseFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCalendartypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCalendartypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új esemény típus</h2>
            <CalendartypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCalendartypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCalendartypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Esemény típus módosítása</h2>
            <CalendartypeFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeenationalityCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeenationalityCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új állampolgárság</h2>
            <EmployeenationalityFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeenationalityUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeenationalityUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Állampolgárság módosítása</h2>
            <EmployeenationalityFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitygroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitygroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új tevékenység</h2>
            <ActivitygroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitygroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitygroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Tevékenység módosítása</h2>
            <ActivitygroupFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitysubgroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitysubgroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új altevékenység</h2>
            <ActivitysubgroupFormComponent groupId={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitysubgroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitysubgroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Altevékenység modosítása</h2>
            <ActivitysubgroupFormComponent groupId={window.App.state.propsGroupId}
                                           initialValues={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmploymentworkorderCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmploymentworkorderCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új munkarend</h2>
            <EmploymentworkorderFormComponent groupId={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmploymentworkorderUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmploymentworkorderUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Munkarend modosítása</h2>
            <EmploymentworkorderFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmploymenttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmploymenttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új foglalkoztatás forma</h2>
            <EmploymenttypeFormComponent groupId={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmploymenttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmploymenttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Foglalkoztatás forma modosítása</h2>
            <EmploymenttypeFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnasCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnasCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új UNAS kapcsolat</h2>
            <UnasFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnasUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnasUpdate: false})
                   }}>
            <h2 className={"text-primary"}>UNAS kapcsolat modosítása</h2>
            <UnasFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarTechnologyCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarTechnologyCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új technológia</h2>
            <TechnologyFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarTechnologyUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarTechnologyUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Technológia módosítása</h2>
            <TechnologyFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPricelistitemCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPricelistitemCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új árlista </h2>
            <PricelistitemFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPricelistitemUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPricelistitemUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Árlista módosítása</h2>
            <PricelistitemFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMachineCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új gép</h2>
            <MachineFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMachineUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Gép módosítása</h2>
            <MachineFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(SettingsDashboardPage);
