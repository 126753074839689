import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import axios from "axios";

import {Panel} from "primereact/panel";

const ProductionsumListComponent = (props) => {

  const {t} = props;
  const [productionsum, setProductionsum] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/project/"+ window.App.state.projectitemRowData.project.id +"/items/" + window.App.state.projectitemRowData.id + "/production-summary",
      //url: process.env.REACT_APP_API_HOST + "/project/1/items/63/production-summary",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then((response) => {
        setProductionsum(response.data.tables);
        setLoading(false);
      })
      .catch((error) => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderProductionsum === true])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>Munkaidő</h2>
      <div className={"p-grid"}>
        {window.App.state.projectitemRowData.name &&
          <div className={"p-col"}>
            <small className={"p-text-uppercase"}>
              {t('project')} tétel
            </small>
            <p className={"p-mt-0 p-mb-0 p-text-bold"}>
              <>{window.App.state.projectitemRowData.name}</>
            </p>
          </div>
        }
        {window.App.state.projectitemRowData.quantity &&
          <div className={"p-col"}>
            <small className={"p-text-uppercase"}>
              Mennyiség
            </small>
            <p className={"p-mt-0 p-mb-0 p-text-bold"}>
              <>{window.App.state.projectitemRowData.quantity} {window.App.state.projectitemRowData.unitName} </>
            </p>
          </div>
        }
        {window.App.state.projectitemRowData.serial &&
          <div className={"p-col"}>
            <small className={"p-text-uppercase"}>
              Sorszám
            </small>
            <p className={"p-mt-0 p-mb-0 p-text-bold"}>
              <>{window.App.state.projectitemRowData.serial}</>
            </p>
          </div>
        }
        {window.App.state.projectitemRowData.projectName &&
          <div className={"p-col"}>
            <small className={"p-text-uppercase"}>
              {t('project')}
            </small>
            <p className={"p-mt-0 p-mb-0 p-text-bold"}>
              <>{window.App.state.projectitemRowData.projectName}</>
            </p>
          </div>
        }
      </div>
      <hr/>
      <Panel className={"p-mt-3"}>

        {productionsum && productionsum[0] &&
          <>
            {productionsum.map((table, index) => {
              return (
                <>
                  <h3 className="">{table.title}</h3>
                  <table className={"p-datatable p-datatable-striped scale-" + localStorage.getItem('scale')}
                         style={{width: '100%'}} cellSpacing={0}>
                    <thead className="p-datatable-thead">
                    <tr>
                      {table.head.map((col, index) => {
                        if (col) {
                          return (
                            <th>
                              {col}
                            </th>
                          )
                        } else {
                          return (
                            <th></th>
                          )
                        }
                      })}
                    </tr>
                    </thead>

                    <tbody className="p-datatable-tbody">
                    {table.rows.map((row, index) => {
                      return (
                        <tr>
                          {row.map((col, index) => {
                            if (col) {
                              return (
                                <td>
                                  {typeof col === "number"
                                    ? new Intl.NumberFormat('hu-HU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(col)
                                    : col}
                                </td>
                              )
                            } else {
                              return (
                                <td></td>
                              )
                            }
                          })}
                        </tr>
                      )
                    })}
                    </tbody>

                    {table.foot &&
                      <tfoot className="p-datatable-tfoot">
                      <tr>
                        {table.foot.map((col, index) => {
                          if (col) {
                            return (
                              <td>
                                {typeof col === "number"
                                  ? new Intl.NumberFormat('hu-HU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(col)
                                  : col}
                              </td>
                            )
                          } else {
                            return (
                              <td></td>
                            )
                          }
                        })}
                      </tr>
                      </tfoot>
                    }
                  </table>
                </>
              )
            })}
          </>
        }

      </Panel>
    </>
  )
}
export default withTranslation('common')(ProductionsumListComponent);
