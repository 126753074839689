import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import moment from "moment/moment";

const MachinelogListComponent = (props) => {

  const {t} = props;
  const [machinelog, setMachinelog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderMachinelogList: false})

      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/machine_log?" + JSON.stringify(lazyParams),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setMachinelog(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderMachinelogList === true])

  return (
    <>
      <DataTable size={localStorage.getItem('scale')}
        emptyMessage="Nincs találat."
        value={machinelog} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows}
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="issueDate" header="Időpont" sortable filter filterPlaceholder="Időpont" body={(rowData) => {
          if (rowData.issueDate !== null) {
            return (
              <span>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD HH:mm:ss')}</span>
            )
          }
        }}></Column>
        <Column field="machineName" header="Gép" sortable filter filterPlaceholder="Gép" />
        <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló" />
        <Column field="projectNumber" header={t('projectNumber')} sortable filter filterPlaceholder={t('projectNumber')} />
        <Column field="projectItemName" header="Tétel" sortable filter filterPlaceholder="Tétel" />
        <Column field="productionItemName" header="Feladat" sortable filter filterPlaceholder="Feladat" />
        <Column field="operation" header="Művelet" sortable filter filterPlaceholder="Művelet" />
        <Column field="mainTime" header="Főidő" sortable filter filterPlaceholder="Főidő" body={(rowData) => {
          return(
            <>
              {typeof rowData.mainTime === "number"
                ? new Intl.NumberFormat('hu-HU', { minimumFractionDigits: 1, maximumFractionDigits:1 }).format(rowData.mainTime)
                : rowData.mainTime}
            </>
          )
        }} />
        <Column field="auxiliaryTime" header="Mellékidő" sortable filter filterPlaceholder="Mellékidő" body={(rowData) => {
          return(
            <>
              {typeof rowData.auxiliaryTime === "number"
                ? new Intl.NumberFormat('hu-HU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(rowData.auxiliaryTime)
                : rowData.auxiliaryTime}
            </>
          )
        }} />
        <Column field="downTime" header="Állásidő" sortable filter filterPlaceholder="Állásidő" body={(rowData) => {
          return(
            <>
              {typeof rowData.downTime === "number"
                ? new Intl.NumberFormat('hu-HU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(rowData.downTime)
                : rowData.downTime}
            </>
          )
        }}  />
      </DataTable>
    </>
  )
}
export default withTranslation('common')(MachinelogListComponent);
